import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { validatePhoneNumber } from "../../../utils/validation";
import AcceptTermsFooter from "../Footer/AcceptTermsFooter";
import { strings } from "../../../utils/localization";
import WeGotYourMessage from "../Chat/Form/WeGotYourMessage";
import ChatHeader from "../Chat/Form/ChatHeader";
import ModernInput from "../Input/ModernInput";
import ModernPhoneNumberField from "../../../components/Fields/ModernPhoneNumberField";
import ModerSubmitButton from "../../../components/Buttons/ModerSubmitButton";

const Container = styled.form`
  margin: 0 auto;

  .matadot-live-chat-123789-main-input {
    width: 100%;
    height: 46px;
    border-radius: 5px !important;
  }

  .flag-dropdown {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
`;

const Message = styled.p`
  margin-bottom: 15px;
  font-size: 13px;
  color: #474657;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Note = styled.p`
  margin-top: -5px;
  margin-bottom: 5px;
  font-size: 12px;
  color: #c43423;
  margin-bottom: 2px;
`;

const FormContainer = styled.div`
  padding: 5px 20px;
`;

const Form = styled.div`
  border-radius: 14px 14px 0px 14px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
  padding: 12px;
  border-radius: 16px;
  max-width: 100%;

  .matadot-live-chat-123789-main-input {
    background-color: #f9f9f9;
    border-bottom-width: 1.3px;
    border-top: 0px;
    border-right: 0px;
    border-radius: 0px;
    color: #17181b;
  }

  .matadot-live-chat-123789-main-input:focus {
    border-bottom-color: ${({ primaryColor }) => primaryColor};
  }
`;

const InputBox = styled.div``;

const WeGotContainer = styled.div`
  padding: 0px 18px;
`;

const Header = styled.div`
  height: 50px;
`;

const CustomSubmitForm = ({
  chatData,
  submit,
  selectedQuestion,
  setSelectedQuestion,
  name,
  setName,
  phoneNumber,
  setPhoneNumber,
  description,
  answer,
  dateQuestion,
  typingTwo,
  viewIndex,
  questionType,
  scrollToBottom,
  typingThree,
  firstAndLastNameSeparately,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  goBack,
}) => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [mobileNumberError, setMobileNumberError] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    setSelectedQuestion("");
  }, []);

  useEffect(() => {
    if (formSubmitted) {
      validatePhone({ value: phoneNumber });
    }
  }, [formSubmitted, phoneNumber]);

  useEffect(() => {
    setIsFormValid(!mobileNumberError && phoneNumber && selectedQuestion);
  }, [mobileNumberError, phoneNumber, selectedQuestion]);

  const handleNameChange = (value) => {
    setName(value);
  };

  const handleMobileNumberChange = (value) => {
    setPhoneNumber(value);
    validatePhone({ value });
  };

  const handleMessageChange = (value) => {
    setSelectedQuestion(value);
  };

  const handleSubmit = (e) => {
    const isNameValid = checkNameValidation();
    setFormSubmitted(true);
    e.preventDefault();
    if (!isFormValid || !isNameValid || !selectedQuestion) return;
    submit({ viewIndex: 3, send: true });
  };

  const validatePhone = ({ value }) => {
    const text = value || phoneNumber;
    const isMobileNumberValid = text.trim() !== "" && validatePhoneNumber(text);
    setMobileNumberError(!isMobileNumberValid);
  };

  const getPhoneNumberFromClipboard = (event) => {
    let pasteValue = (event.clipboardData || window.clipboardData).getData(
      "text"
    );
    pasteValue = pasteValue.replace(/-|\s/g, "");
    pasteValue = pasteValue.replace("(", "");
    pasteValue = pasteValue.replace(")", "");
    pasteValue = pasteValue.replace("tel:", "");
    pasteValue = pasteValue.replace("+", "");

    if (chatData?.organization_country_code?.toLowerCase() === "au") {
      if (pasteValue.length === 9) {
        pasteValue = `0${pasteValue}`;
      }
      if (pasteValue.length >= 11) {
        pasteValue = `0${pasteValue && pasteValue.slice(2)}`;
      }
    } else {
      if (pasteValue.length === 10) {
        pasteValue = `1${pasteValue}`;
      }
      if (pasteValue.length === 11) {
        pasteValue = `1${pasteValue && pasteValue.slice(1)}`;
      }
    }

    validatePhone({ value: pasteValue });
    setPhoneNumber(pasteValue);
  };

  const renderWeGot = () => {
    let organization_name =
      chatData && chatData.organization_name ? chatData.organization_name : "";
    let backgroundColor = chatData && chatData.color ? chatData.color : "red";
    let second_message =
      chatData && chatData.texts.second_message
        ? chatData.texts.second_message
        : "";
    let color = chatData && chatData.color ? chatData.color : "white";
    let img = chatData && chatData.image_url ? chatData.image_url : "";

    return (
      <WeGotContainer>
        <WeGotYourMessage
          typingThree={typingThree}
          img={img}
          organization_name={organization_name}
          color={color}
          second_message={second_message}
          backgroundColor={backgroundColor}
          secondaryColor={chatData.secondaryColor}
        />
      </WeGotContainer>
    );
  };

  const checkNameValidation = () => {
    const { isCustomerNameRequired } = chatData || {};

    let valid = true;

    if (isCustomerNameRequired) {
      if (firstAndLastNameSeparately) {
        valid = firstName.trim() !== "" && lastName.trim() !== "";
      } else {
        valid = name.trim() !== "";
      }
    }

    if (!valid) {
      setTimeout(() => {
        scrollToBottom();
      }, 100);
    }

    return valid;
  };

  const renderName = () => {
    const { isCustomerNameRequired } = chatData || {};

    if (firstAndLastNameSeparately) {
      return (
        <>
          <ModernInput
            placeholder={strings.FIRST_NAME}
            color={chatData.color}
            value={firstName}
            backgroundColor="#fff"
            onChange={(value) => setFirstName(value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit();
              }
            }}
            onFocus={scrollToBottom}
            required
            enableValidation={isCustomerNameRequired}
            formSubmitted={formSubmitted}
          />
          {!isCustomerNameRequired ? <br /> : <div style={{ height: 2 }} />}
          <ModernInput
            placeholder={strings.LAST_NAME}
            color={chatData.color}
            value={lastName}
            backgroundColor="#fff"
            onChange={(value) => setLastName(value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit();
              }
            }}
            onFocus={scrollToBottom}
            required
            enableValidation={isCustomerNameRequired}
            formSubmitted={formSubmitted}
          />
          <div style={{ height: 8 }}></div>
        </>
      );
    }

    return (
      <>
        <ModernInput
          placeholder={strings.FULL_NAME}
          color={chatData.color}
          value={name}
          backgroundColor="#fff"
          onChange={handleNameChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSubmit();
            }
          }}
          onFocus={scrollToBottom}
          required
          enableValidation={isCustomerNameRequired}
          formSubmitted={formSubmitted}
          borderDisabled
          marginBottom={20}
        />
      </>
    );
  };

  const renderContent = () => {
    if (viewIndex === 3) {
      return renderWeGot();
    }

    return (
      <div>
        <Header>
          <ChatHeader goBack={goBack} />
        </Header>
        <FormContainer>
          <Message>
            {description ||
              "Add your details and a short message, we’ll respond with a text."}
          </Message>
          <Form primaryColor={chatData.color}>
            <InputBox>{renderName()}</InputBox>

            <ModernPhoneNumberField
              value={phoneNumber}
              onChange={(value) => handleMobileNumberChange(value)}
              defaultCountryFlag={
                chatData?.organization_country_code?.toLowerCase() || "us"
              }
              getPhoneNumberFromClipboard={getPhoneNumberFromClipboard}
            />

            <Note
              style={{
                visibility: mobileNumberError ? "visible" : "hidden",
                marginTop: 3,
                marginBottom: 5,
              }}
            >
              A valid mobile phone number is required.
            </Note>

            <InputBox>
              <ModernInput
                placeholder="Message"
                color={chatData.color}
                value={selectedQuestion}
                backgroundColor="#fff"
                onChange={handleMessageChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
                enableValidation
                className={"form-input-message"}
                maxLength={1000}
                required
                formSubmitted={formSubmitted}
                borderDisabled
              />
            </InputBox>

            <AcceptTermsFooter
              color={chatData.termsLink}
              termsLink={chatData.termsLink}
              margin={0}
            />

            <ModerSubmitButton
              disabled={!isFormValid}
              marginTop={18}
              color={chatData.color}
              secondaryColor={chatData.secondaryColor}
            />
          </Form>
        </FormContainer>
      </div>
    );
  };

  return <Container onSubmit={handleSubmit}>{renderContent()}</Container>;
};

export default CustomSubmitForm;
